<template lang="pug">
.om-wizard-separator
  .om-wizard-separator-line
  .om-wizard-separator-content
    slot
  .om-wizard-separator-line
</template>

<script>
  export default {};
</script>

<style lang="sass">
  .om-wizard-separator
    display: flex
    flex-direction: column
    align-items: center

    &-line
      height: 5.75rem
      border: 1px dashed #D2D2D2
      writing-mode: vertical-rl
      text-orientation: upright

    &-content
      margin: 0.5625rem 0
      font-style: italic
      font-size: 1.25rem
      line-height: 1.5rem
      color: #969BA0
</style>
